import Vuex from 'vuex';
import Vue from 'vue';
Vue.use(Vuex);

export default new Vuex.Store({
    
    state:{
        vacancies: {}
    },

    getters: {
        getVacancyStageById: (state) => (vacancyId) => {
            if(typeof state.vacancies[vacancyId] === 'undefined') {
                state.vacancies[vacancyId] = 0;
            };
            return state.vacancies[vacancyId];
        }
    },

    actions: {
        
    },

    mutations:{
        plusStage(state, payload) {
            state.vacancies[payload.vacancyId]++;
        }
    }

});