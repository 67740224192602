import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("@/components/Home"),
      children: [
        {
          path: "en",
          children: [
            {
              path: "",
              meta: {
                lang: "en"
              }
            },
            {
              path: ":folder",
              meta: {
                lang: "en"
              }
            }
          ]
        },
        {
          path: ":folder",
          meta: {
            lang: "ru"
          }
        }
      ]
    }
  ]
});

export default router;
