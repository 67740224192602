import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import i18n from "./i18n";
import router from "./router";
import VueYandexMetrika from "vue-yandex-metrika";
import VueMeta from 'vue-meta';
import YmapPlugin from 'vue-yandex-maps';

Vue.use(YmapPlugin, {
  apiKey: 'dc3bd1bb-d761-437e-bd7f-96b3f9314654',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1'
});

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
axios.defaults.withCredentials = true;

Vue.use(VueMeta);
Vue.use(VueAxios, axios);
Vue.use(VueYandexMetrika, {
  id: 85474132,
  router: router,
  env: process.env.NODE_ENV,
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true
});

window.onload = function() {
  new Vue({
    router,
    i18n,
    render: h => h(App)
  }).$mount("#app");
};
